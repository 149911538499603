<template>
  <div class="d-flex w-100 dark-hover">
    <div class="image-container">
      <img class="profile-circle" v-if="iconUrl" :src="iconUrl" />

      <img v-else-if="!isKid"  class="profile-circle" :src="require('@/assets/icons/generic-no-bg.png')"
      />
      <img v-else  class="profile-circle" :src="require('@/assets/icons/generic-no-bg.png')"
      />
    </div>
    <div>
      <p class="heading">{{ this.heading }} <span v-if="isKid" class="kids-tag">{{$t("kids")}}</span></p>
      <p class="description">{{ this.description }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ["heading", "description", "iconUrl", "isKid"],
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "./ProfileTile.scss"
</style>
